<template>
  <div class="Report__Entity">
    <Entity
      ref="entity"
      entity-type="VehicleContract"
      :entity-id="contractId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי חוזה"
      title="חוזה שכירות לרכב"
      back-button-text="רשימת רכבים"
      back-button-url="vehicles"
      :widgets="widgets"
      :preview="preview"
      :actions="actions"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">לוחית רישוי:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Vehicle.Name }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">לקוח:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.Name }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך תחילה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="StartDate"
          /></span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך סיום:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="EndDate"
          /></span>
        </div>
        <!-- <div class="Accident__Entity__Details__BaseDetails__Stage">
            <span class="ltr">תאריך יצירה:</span>
            <span class="Accident__Entity__Details__BaseDetails__Stage__Value
                        value has-text-weight-bold"
              >{{CreateDate | date}}</span
            >
        </div> -->
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך עדכון:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            ><FieldDateHourInline :value="UpdateDate"
          /></span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import VehicleContractEntityDetails from "@/components/VehicleContractEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import FieldDateHourInline from "@/components/Fields/FieldDateHourInline.vue";
import DialogReportHistory from "@/components/DialogReportHistory.vue";
import WidgetService from "../services/WidgetService";
import VehicleService from "../services/VehicleService";

const { mapFields } = createHelpers({
  getterType: "contract/getField",
  mutationType: "contract/updateField",
});

export default {
  components: {
    Entity,
    FieldDateHourInline,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    contractId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        {
          ...WidgetService.Vehicle,
          namespace: "contract",
          fetch: true,
          delegate: VehicleService.getVehicle,
        },
        { ...WidgetService.Profile, namespace: "contract" },
        {
          ...WidgetService.VehicleContractDocuments,
          namespace: "contract",
          documentType: "TransferHtml",
          title: "הצהרת חוכר",
          hasDocument: this.data?.Contract.HasTransferHtml,
        },
        {
          ...WidgetService.VehicleContractDocuments,
          namespace: "contract",
          documentType: "RentalPickUpFormHtml",
          title: "טופס איסוף השכרה",
          hasDocument: this.data?.Contract.HasRentalPickUpFormHtml,
        },
        {
          ...WidgetService.VehicleContractDocuments,
          namespace: "contract",
          documentType: "RentalReturnFormHtml",
          title: "טופס החזרת השכרה",
          hasDocument: this.data?.Contract.HasRentalReturnFormHtml,
        },
        {
          ...WidgetService.VehicleContractDocuments,
          namespace: "contract",
          documentType: "DriversLicenseHtml",
          title: "רישיון נהיגה",
          hasDocument: this.data?.Contract.HasDriversLicenseHtml,
        },
      ];
    },
    innerComponent() {
      return VehicleContractEntityDetails;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    actions() {
      return !this.isAdmin
        ? null
        : [
            {
              text: "היסטוריית אירועים",
              onClick: () => {
                VehicleService.getVehicleContractHistory(this.contractId).then(
                  (r) => {
                    this.data = r.data;
                    this.data.Items.forEach((item) => {
                      item.DriversLicenseHtml = !!item.DriversLicenseHtml;
                      item.RentalPickUpFormHtml = !!item.RentalPickUpFormHtml;
                      item.RentalReturnFormHtml = !!item.RentalReturnFormHtml;
                      item.TransferHtml = !!item.TransferHtml;
                    });
                    this.$buefy.modal.open({
                      component: DialogReportHistory,
                      props: {
                        data: this.data,
                      },
                      canCancel: false,
                      hasModalCard: true,
                      trapFocus: true,
                    });
                  }
                );
              },
            },
          ];
    },

    ...mapFields(["StartDate", "UpdateDate", "EndDate", "Vehicle", "Profile"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.contractId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      VehicleService.updateContract(this.Vehicle.Id, this.contractId, model)
        .then(() => {
          this.init(refresh);
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "contract/loadContract",
    }),
  },
  // watch: {
  //   id(old, newValue) {
  //     console.log(old, newValue);
  //   },
  // },
};
</script>

<style>
</style>
